import styled from 'styled-components'
import * as palette from '../../styles/variables'
import { device, width } from '../../styles/device'

const ProductWrapStyled = styled.div`
    width: 100%;
    background-color: ${palette.blue};
    padding-top: 30px;
    padding-bottom: 30px;

    @media ${device.tablet} {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .container {
        max-width: ${width.xl};
        display: flex;
        flex-direction: row;
        margin: 0 auto;
    }
`;

export default ProductWrapStyled
